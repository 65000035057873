import { useEffect, useState } from 'react';
import './index.scss'; //another copy is rendered through the nav repo, footer css
import { Label, Select, Spin, Button, TextArea, Input } from '@opsdti-global-component-library/amgen-design-system';
import { apiResponse, getUserDetailsFromOktaToken } from '../../utility/commonMethods';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUserAppInfo } from '../../hooks/use-user-app-info';

interface FooterFormProps {
  modelType: string;
  modelClose: () => void;
}

const FooterForm = (props: FooterFormProps) => {
  const page = [
    { label: 'Home', value: 'Home' },
    { label: 'Brand', value: 'Brand' },
    { label: 'People', value: 'People' },
    { label: 'Pipeline', value: 'Pipeline' },
    { label: 'Supply', value: 'Supply' },
    { label: 'Manufacturing', value: 'Manufacturing' },
    { label: 'Finance', value: 'Finance' },
    { label: 'Prioritized Agenda', value: 'Prioritized Agenda' },
    { label: 'Intelligence', value: 'External' },
    { label: 'Key Events', value: 'Key Events' },
  ];
  const [isLoading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [Comments, setComments] = useState('');
  //const [checkedFeatures, setCheckedFeatures] = useState(page);
  const [accessRequestSuccessful, setAccessRequestSuccessful] = useState(false);
  const [assignedTo, setAssignedTo] = useState('');
  const [subject, setSubject] = useState('');
  const [isUserDetailsPresent, setUserDetailsPresent] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const userInfo = getUserDetailsFromOktaToken();
  const userAppInfo = useUserAppInfo();

  useEffect(() => {
    if (userAppInfo !== null) {
      //console.log('userAppInfo----------', 'userDetails' in userAppInfo);
      if ('userDetails' in userAppInfo) {
        setUserDetailsPresent(true);
      } else {
        setUserDetailsPresent(false);
      }
    }
  }, [userAppInfo]);

  const submitAccessRequest = async () => {
    const payload = {
      form_type: props.modelType,
      section: assignedTo,
      subject: subject,
      description: Comments,
      name: isUserDetailsPresent ? userAppInfo?.userDetails?.Full_Name : userName,
      email: isUserDetailsPresent ? userInfo?.email : userEmail,
    };
    setLoading(true);
    try {
      const res = await apiResponse('post', 'post-footer-data', [], payload);
      if (res?.data.status === 'SUCCESS') {
        setLoading(false);
        setAccessRequestSuccessful(true);
        setTimeout(() => {
          props.modelClose();
        }, 2000);
      } else {
        setLoading(false);
        toast.error('Failed to Submit Request!!');
      }
    } catch (error) {
      toast.error('Something Went Wrong!!');
      setLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    if (props.modelType === '') return;
    //console.log('modelType', props.modelType);
    setAssignedTo('');
    setComments('');
    setSubject('');
    setAccessRequestSuccessful(false);
    setDisabled(true);
  }, [props.modelType]);

  return (
    <div className="">
      {page.length > 0 &&
        userAppInfo !== null &&
        (isLoading ? (
          <div className="adm-loader-container">
            <Spin />
          </div>
        ) : !isLoading && accessRequestSuccessful ? (
          <p>Submission Successfull</p>
        ) : (
          <form className="request-access footer-form-page">
            <div className="request-access__user-info">
              <div className="request-access__row-field">
                <Label className="request-access__label">Username</Label>
                <Input
                  className="request-access__input"
                  value={isUserDetailsPresent ? userAppInfo?.userDetails?.Full_Name : userName}
                  disabled={isUserDetailsPresent}
                  onChange={e => setUserName(e.target.value)}
                />
              </div>
              <div className="request-access__row-field margin-right-0">
                <Label className="request-access__label">Email</Label>
                <Input
                  className="request-access__input"
                  value={isUserDetailsPresent ? userAppInfo?.userDetails?.Email : userEmail}
                  disabled={isUserDetailsPresent}
                  onChange={e => setUserEmail(e.target.value)}
                />
              </div>
            </div>
            {props.modelType === 'CONTACT' ? (
              <div className="request-access__row-field">
                <Label className="request-access__label">Subject</Label>
                <Input className="request-access__input footer-form-subject" value={subject} onChange={(e: any) => setSubject(e.target.value)} />
              </div>
            ) : null}
            <hr></hr>
            <Label className="request-access__label">
              Page Section <sup>*</sup>
            </Label>
            <div className="footer-form-page-select">
              <Select
                options={page}
                value={assignedTo}
                onChange={value => {
                  setAssignedTo(value);
                  setDisabled(false);
                }}
                className="footer-form-dropdown"
              />
            </div>
            <div>
              <Label className="request-access__label">Comments / Description</Label>
              <TextArea
                id="Comments"
                className="request-access__textarea footer-form-textarea"
                value={Comments}
                onChange={e => setComments(e.target.value)}
              ></TextArea>
            </div>
            <Button type="secondary" className="" onClick={submitAccessRequest} disabled={isDisabled} text="SUBMIT" />
          </form>
        ))}
      <ToastContainer
        className="toast-position"
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default FooterForm;
